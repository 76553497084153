import { useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { usePremiumSelector } from "../../../redux/reducers/PremiumReducer/PremiumReducer";
import { useConfigurationSelector } from "../../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { createAccessRequestToASpecificSubscription, getProductUrlByWS } from "../../../apis/AdminCenter/adminCenterApi";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useTranslate } from "../../../hook/useTranslate";
import { useLogic } from "../../Premium/UpgradePremium.logic";
import { appSourceBaseUrl } from "./UpgradeDialog";
import { useAppConfigurationSelector } from "../../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
export const useUpgradeLogic = (props) => {
    const upgradePremiumLogic = useLogic(props);
    const { locale } = useMsTeamsSelector("locale");
    const { data: config } = useConfigurationSelector("data");
    const [superAdminIdToRequest, setSuperAdminToRequest] = useState();
    const { adminCenterData } = usePremiumSelector("adminCenterData");
    const { from } = useAppConfigurationSelector("from");
    const t = useTranslate(translations);
    const showPaymentPage = () => {
        upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: true }));
        props.appInsight?.trackEvent({
            name: "premium_manage_open",
        });
        if (config.appSourceOfferId != undefined && config.appSourceOfferId.trim() != "") {
            upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false, isClosable: false, appSourceOpened: true }));
            setTimeout(() => {
                upgradePremiumLogic.setState((prev) => ({ ...prev, isClosable: true }));
                window.open(appSourceBaseUrl + locale + "/product/web-apps/" + config.appSourceOfferId, "_blank");
            }, 4000);
        }
        if (from == "Map") {
            window.open(getProductUrlByWS(upgradePremiumLogic.user, upgradePremiumLogic.licencePlanId, undefined, config.adminCenterBaseUrl));
        }
        else {
            microsoftTeams.dialog.url.open({
                url: getProductUrlByWS(upgradePremiumLogic.user, upgradePremiumLogic.licencePlanId, config.apiSubscription),
                size: {
                    width: 1300,
                    height: 500,
                },
            }, (data) => {
                if (data.err && !data.result) {
                    upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false }));
                    props.appInsight?.trackEvent({
                        name: "premium_manage_cancel",
                    });
                }
                else {
                    upgradePremiumLogic.setLicencePlanId(data.result);
                    upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: true }));
                    props.appInsight?.trackEvent({ name: "premium_manage_assign" });
                }
            });
        }
    };
    const updateSuperAdminToRequest = (event, data) => {
        if (!data)
            return null;
        const selectedValue = data.value.value;
        if (!selectedValue)
            return null;
        setSuperAdminToRequest(adminCenterData.tenantSuperAdmins?.find(tsa => tsa.aadId === selectedValue));
    };
    const sendAccessRequest = async () => {
        upgradePremiumLogic.setState((prev) => ({ ...prev, sendingAccessRequest: true }));
        await createAccessRequestToASpecificSubscription(upgradePremiumLogic.user, props.appId, upgradePremiumLogic.state.requestAccessMessage, locale, superAdminIdToRequest, config.adminCenterBaseUrl);
        upgradePremiumLogic.setState((prev) => ({ ...prev, sendingAccessRequest: false, showRequestAccessDialog: false, requestAccessMessage: "", isRequestSent: true }));
    };
    return {
        closeDialog: upgradePremiumLogic.closeDialog,
        state: upgradePremiumLogic.state,
        showPaymentPageForSuperAdmin: upgradePremiumLogic.showPaymentPage,
        licencePlanId: upgradePremiumLogic.licencePlanId,
        setState: upgradePremiumLogic.setState,
        sendAccessRequest: sendAccessRequest,
        showPaymentPage,
        setSuperAdminToRequest,
        updateSuperAdminToRequest,
        user: upgradePremiumLogic.user
    };
};
