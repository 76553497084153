import {Component, PropsWithChildren} from 'react';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {createBrowserHistory} from "history";
import withRouter from "./withRouter.hook";
import store from "../../redux/store";

const browserHistory = createBrowserHistory();

const reactPlugin: any = new ReactPlugin();
export let appInsightInstance: any;

class TelemetryService extends Component<PropsWithChildren> {

    override componentDidMount() {
        const configuration = store.getState().configuration;
        const key = configuration?.data.appInsightKey;
        if (!key) return;

        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });
        appInsights.loadAppInsights();
        appInsightInstance = appInsights.appInsights;
    }

    override render() {
        return this.props.children;
    }
}

export default withRouter(withAITracking(reactPlugin, TelemetryService))