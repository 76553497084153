import { fixEvents } from "../components/calendar/Calendar.utils";
import { AxiosConfig, tryCatch } from "front";
export const getEvent = (calendarId, eventId) => new Promise(async (resolve, reject) => {
    try {
        const res = await AxiosConfig.instance.get(`/calendar/${calendarId}/event/${eventId}`);
        return resolve(res.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get calendar event");
    }
});
export const getEvents = (calendarId, isResourceMode, start, end, tag = "") => new Promise(async (resolve, reject) => {
    try {
        const encodedTag = encodeURIComponent(tag);
        const res = await AxiosConfig.instance.get(`/calendar/${calendarId}/events?isResourceMode=${isResourceMode}&start=${start}&end=${end}&tag=${encodedTag}`);
        const events = res.data.map((e) => {
            const ev = fixEvents(e);
            return ev;
        });
        return resolve(events);
    }
    catch (error) {
        console.error(error);
        reject(error);
    }
});
export const createEvent = tryCatch(async (data) => {
    const res = await AxiosConfig.instance.post(`/calendar/${data.calendarId}/event`, JSON.stringify(data.event));
    const ev = fixEvents(res.data);
    return ev;
});
export const updateEvent = tryCatch(async (data) => {
    const res = await AxiosConfig.instance.patch(`/calendar/${data.calendarId}/event/${data.eventId}`, JSON.stringify(data.event));
    return res.data;
});
export const deleteEvent = async (calendarId, eventId) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.delete(`/calendar/${calendarId}/event/${eventId}`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete event");
    }
});
export const deleteEventsByGroupId = async (calendarId, groupId) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.delete(`/calendar/${calendarId}/groups/${groupId}`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete event");
    }
});
export const deleteCustomOccurencesByGroupId = (calendarId, groupId) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.delete(`/calendar/${calendarId}/groups/${groupId}/custom`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete event");
    }
});
export const deleteTag = (calendarId, tag) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.delete(`/calendar/${calendarId}/tags/${tag}`);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't delete tag");
    }
});
export const renameTag = (calendarId, oldTag, newTag) => new Promise(async (resolve, reject) => {
    try {
        await AxiosConfig.instance.patch(`/calendar/${calendarId}/tags/${oldTag}`, newTag);
        return resolve();
    }
    catch (error) {
        console.error(error);
        reject("Can't rename tag");
    }
});
