import momentTz from "moment-timezone";
import { translations } from "../../../translations";
import { generateDropdownItems, updateEventRRule, updateEventEndDate, updateEventEndDateTime, updateEventStartDate, updateEventStartDateTime, updateEventAllDayToggle } from "../eventUtils";
import { IOptionRecurrence } from "../../../interfaces/Enums";
import { openPremiumDialog, useModalSelector, useMsTeamsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, userHasRightsToEdit } from "front";
import { createRef, useEffect, useState } from "react";
import { formatEventRRuleToRRulePlugin } from "../../calendar/Calendar.utils";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { useDispatch, useSelector } from "react-redux";
export const freeDisplayedResourcesLimit = 3;
export const labelsMaxWidth = "180px";
export const timeZones = momentTz.tz.names();
export const useLogic = (props) => {
    const t = useTranslate(translations);
    const rruleDaysMonthsTranslations = {
        dayNames: [t("RRuleSunday"), t("RRuleMonday"), t("RRuleTuesday"), t("RRuleWednesday"), t("RRuleThursday"), t("RRuleFriday"), t("RRuleSaturday")],
        monthNames: [
            t("RRuleJanuary"),
            t("RRuleFebruary"),
            t("RRuleMarch"),
            t("RRuleApril"),
            t("RRuleMay"),
            t("RRuleJune"),
            t("RRuleJuly"),
            t("RRuleAugust"),
            t("RRuleSeptember"),
            t("RRuleOctober"),
            t("RRuleNovember"),
            t("RRuleDecember"),
        ],
    };
    const rruleTranslations = [
        { content: "every", value: t("RRuleEvery") },
        { content: "day", value: t("RRuleDay") },
        { content: "days", value: t("RRuleDays") },
        { content: "month", value: t("RRuleMonth") },
        { content: "months", value: t("RRuleMonths") },
        { content: "year", value: t("RRuleYear") },
        { content: "years", value: t("RRuleYears") },
        { content: "on the", value: t("RRuleOnThe") },
        { content: "and", value: t("RRuleAnd") },
        { content: "nd", value: t("RRuleNd") },
        { content: "th", value: t("RRuleTh") },
        { content: "st", value: t("RRuleSt") },
        { content: "week", value: t("RRuleWeek") },
        { content: "weeks", value: t("RRuleWeeks") },
        { content: "on", value: t("RRuleOn") },
        { content: "for", value: t("RRuleFor") },
        { content: "times", value: t("RRuleTimes") },
        { content: "until", value: t("RRuleUntil") },
    ];
    const displayRecurrence = generateDropdownItems(IOptionRecurrence, ["NotRepeat", "Workweek", "Daily", "Weekly", "Monthly", "Yearly", "Custom"]);
    const { calendar } = useCalendarCache();
    const { isPremium } = usePremiumSelector("isPremium");
    const { userPerm } = usePermissionsSelector("userPerm");
    const { locale } = useMsTeamsSelector("locale", "userMail");
    const { isOpen } = useModalSelector("isOpen");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const LFormat = momentTz.localeData(locale).longDateFormat("LT");
    const use12HoursFormat = LFormat.split(" ").length > 1 && LFormat.split(" ").pop() === "A";
    const richTextEditorRef = createRef();
    const dispatch = useDispatch();
    const [isColorPickerVisible, setColorPickerVisible] = useState(false);
    const [state, setState] = useState({
        readOnly: true,
        currentTag: "",
        address: undefined,
        showCustomRecurrenceDialog: false,
        showGoPremiumRecurrenceAlert: false,
        openPickerEmoji: false,
        tagIsLoading: false,
        tagLoadingError: false,
    });
    const [ressourceValue, setRessourceValue] = useState("");
    useEffect(() => {
        initRessourceValue();
    }, [props.resources, props.event.resourceId, props.event.title]);
    const initRessourceValue = () => {
        const initialValue = props.resources.find((item) => item.id.toString() === props.event.resourceId)?.title ?? (props.resources.length > 0 && props.resources[0] ? props.resources[0].title : "");
        setRessourceValue(initialValue);
    };
    useEffect(() => {
        document.onclick = (e) => {
            const popoverColorPicker = document.getElementById("popover-color-picker");
            if (isColorPickerVisible &&
                e.target.id != "color-picker-button" &&
                e.target.id != "color-picker-buttoncolor" &&
                e.target.id != "popover-color-picker" &&
                popoverColorPicker?.contains(e.target) == false) {
                setColorPickerVisible(false);
            }
        };
    }, []);
    useEffect(() => {
        if (!calendar)
            return;
        setState((prev) => ({ ...prev, readOnly: !userHasRightsToEdit(userPerm) }));
    }, [calendar, userPerm]);
    useEffect(() => {
        setState((prev) => ({ ...prev, address: props.event.address }));
    }, [props.event.address]);
    /**
     * Update event start date time
     * @param event event
     * @param data dropdown data
     */
    const onUpdateStartTime = (event, data) => {
        let hours = momentTz(props.event.startDate).get("hours");
        let minutes = momentTz(props.event.startDate).get("minutes");
        if (data.className?.includes("hours-dropdown")) {
            hours = Number(data.value.substring(0, 2));
            if (data.value.includes("PM"))
                hours += 12;
        }
        else {
            minutes = Number(data.value.substring(0, 2));
        }
        props.onUpdateEvent(updateEventStartDateTime(props.event, hours, minutes));
    };
    /**
     * Update event end date time
     * @param event event
     * @param data dropdown data
     */
    const onUpdateEndTime = (event, data) => {
        let hours = momentTz(props.event.endDate).get("hours");
        let minutes = momentTz(props.event.endDate).get("minutes");
        if (data.className?.includes("hours-dropdown")) {
            hours = Number(data.value.substring(0, 2));
            if (data.value.includes("PM"))
                hours += 12;
        }
        else {
            minutes = Number(data.value.substring(0, 2));
        }
        props.onUpdateEvent(updateEventEndDateTime(props.event, hours, minutes));
    };
    /**
     * Transform recurrence rule to string
     */
    const getRRuleToString = () => {
        let rrule = formatEventRRuleToRRulePlugin(props.event);
        if (!rrule)
            return "";
        const gettext = (id) => {
            return rruleTranslations.find((f) => f.content === id)?.value || id.toString();
        };
        return rrule.toText(gettext, rruleDaysMonthsTranslations);
    };
    /**
     * Get event recurrence mode label
     */
    const getRecurrenceModeLabel = () => {
        const recurrenceMode = props.event.modeRecurrence ?? IOptionRecurrence.NotRepeat;
        if (!!props.event.rrule && recurrenceMode === IOptionRecurrence.Custom)
            return getRRuleToString();
        return displayRecurrence.find((f) => f.value === recurrenceMode)?.header;
    };
    /**
     * Clear color
     */
    const onClearColor = () => onUpdateEventFromDropdownOrInput("color")(null, { value: undefined });
    /**
     * On update color
     * @param color color
     */
    const onUpdateColor = (color, event) => {
        onUpdateEventFromDropdownOrInput("color")(null, { value: color.hex });
    };
    /**
     * On add tag
     */
    const onAddTag = () => onUpdateEvent("tags", undefined);
    /**
     * Remove tag
     */
    const onRemoveTag = (index) => () => onUpdateEvent("tags", index);
    /**
     * On update event property from value
     * @param property
     */
    const onUpdateEventFromValue = (property) => async (value) => {
        await onUpdateEvent(property, value);
    };
    const onUpdateEventFromCheckBox = (property) => async (event, data) => {
        if (!data)
            return;
        await onUpdateEvent(property, data.checked);
    };
    // const preventBookDateIssue = () => {
    //   const allEvents =
    // };
    /**
     * On update event property from input or dropdown
     */
    const onUpdateEventFromDropdownOrInput = (property) => async (event, data) => {
        if (!data)
            return;
        await onUpdateEvent(property, data.value);
    };
    const displayColorPicker = () => {
        if (state.readOnly || props.disabled)
            return;
        setColorPickerVisible((prev) => !prev);
    };
    /**
     * On update event
     * @param property property
     * @param value value
     */
    const onUpdateEvent = async (property, value) => {
        let stateUpdate = {};
        let event = { ...props.event };
        switch (property) {
            case "title":
                event = { ...event, title: value };
                break;
            case "description":
                event = { ...event, description: value };
                break;
            case "startDate":
                event = updateEventStartDate(event, value);
                break;
            case "endDate":
                event = updateEventEndDate(event, value);
                break;
            case "color":
                event = { ...event, color: value };
                break;
            case "allDay":
                event = updateEventAllDayToggle(event);
                break;
            case "resourceId":
                event = { ...event, resourceId: value.value + "" };
                break;
            case "modeRecurrence":
                let recurrenceMode = value.value;
                const isPremiumRecurrenceButFreeUser = !isPremium && recurrenceMode !== IOptionRecurrence.NotRepeat;
                if (isPremiumRecurrenceButFreeUser)
                    recurrenceMode = IOptionRecurrence.NotRepeat;
                stateUpdate.showGoPremiumRecurrenceAlert = isPremiumRecurrenceButFreeUser;
                if (recurrenceMode === IOptionRecurrence.Custom) {
                    recurrenceMode = event.modeRecurrence !== IOptionRecurrence.NotRepeat ? event.modeRecurrence : IOptionRecurrence.Custom;
                    stateUpdate.showCustomRecurrenceDialog = true;
                }
                event = updateEventRRule(event, recurrenceMode);
                break;
            case "timeZone":
                event = updateEventRRule({ ...event, timeZone: value }, event.modeRecurrence);
                break;
            case "address":
                if (!value)
                    event = { ...event, address: undefined };
                stateUpdate.address = { ...state.address, fullAddress: value };
                break;
            case "tags":
                if (!props.event.tags)
                    return;
                let temp = new Array(...(event.tags ?? []));
                // Value is index of element to remove
                if (value !== undefined)
                    temp.splice(value, 1);
                else if (!!state.currentTag && !temp.some((item) => item.toLocaleLowerCase() == state.currentTag.toLocaleLowerCase()))
                    temp.push(state.currentTag);
                event = { ...event, tags: temp };
                stateUpdate.currentTag = "";
                break;
            default:
                break;
        }
        await props.onUpdateEvent(event);
        setState(stateUpdate);
    };
    /**
     * Format a date to local date format
     */
    const formatDateToLocalDateFormat = (date) => date.toLocaleDateString();
    /**
     * On current tag change
     * @param event
     */
    const onCurrentTagChange = (event) => setState((prev) => ({ ...prev, currentTag: event.target.value.trim() }));
    /**
     * On close custom recurrence dialog
     */
    const onCloseCustomRecurrenceDialog = () => setState((prev) => ({ ...prev, showCustomRecurrenceDialog: false }));
    /**
     * On confirm recurrence dialog
     */
    const onConfirmCustomRecurrenceDialog = async (rrule) => {
        const event = { ...props.event, modeRecurrence: IOptionRecurrence.Custom, rrule: rrule };
        await props.onUpdateEvent(event);
        setState((prev) => ({ ...prev, showCustomRecurrenceDialog: false }));
    };
    /**
     * On Pick Emoji
     * @param eventMouse
     * @param emojiObject
     */
    const onEmojiClick = async (emojiObject, eventMouse) => {
        setState((prev) => ({ ...prev, emoji: emojiObject, openPickerEmoji: false }));
        const event = { ...props.event, emoji: emojiObject.emoji };
        await props.onUpdateEvent(event);
    };
    /**
     * Show emoji picker
     */
    const showPickerEmoji = () => setState((prev) => ({ ...prev, openPickerEmoji: true }));
    /**
     * Clear emoji on event
     */
    const onClearEmoji = async () => {
        setState((prev) => ({ ...prev, emoji: undefined }));
        const event = { ...props.event, emoji: undefined };
        await props.onUpdateEvent(event);
    };
    const onCheckEmojiPickerAndShareButton = () => {
        if (!isPremium) {
            showPremiumDialog();
        }
    };
    const openChangeTag = async (e, { open }) => {
        /* if (open) {
              if (state.tagIsLoading == false) {
                setState((prev) =>({...prev, tagIsLoading: true}))
                if(!calendar?.id) return;
                mutateTags(calendar.id, {
                    onSuccess : ()=> {
                        setState((prev)=>({...prev, tagIsLoading: false}))
                    },
                    onError : () => {
                        setState((prev)=>({...prev, tagIsLoading: true, tagLoadingError: true}))
                    }
                });
              }
            } else {
              if (state.tagIsLoading && state.tagLoadingError)
                setState((prev)=>({...prev,tagIsLoading: false, tagLoadingError: false}))
            }*/
    };
    /**
     * Show premium dialog
     */
    const showPremiumDialog = () => {
        dispatch(openPremiumDialog(appInsightInstance));
    };
    const handleSearchQueryChange = (event, data) => {
        const value = data.searchQuery?.toString() ?? "";
        setState((prev) => ({ ...prev, currentTag: value }));
    };
    const tagsOnChange = (event, data) => {
        const value = data.value ?? "";
        setState((prev) => ({ ...prev, currentTag: value }));
    };
    const addParticipant = async (e) => {
        const event = { ...props.event, participants: e };
        await props.onUpdateEvent(event);
    };
    return {
        state,
        onUpdateStartTime,
        onUpdateEndTime,
        getRecurrenceModeLabel,
        onClearColor,
        onUpdateColor,
        onAddTag,
        onRemoveTag,
        onUpdateEventFromValue,
        onUpdateEventFromCheckBox,
        formatDateToLocalDateFormat,
        onCurrentTagChange,
        onCloseCustomRecurrenceDialog,
        onConfirmCustomRecurrenceDialog,
        onEmojiClick,
        showPickerEmoji,
        onClearEmoji,
        onCheckEmojiPickerAndShareButton,
        onUpdateEventFromDropdownOrInput,
        richTextEditorRef,
        handleSearchQueryChange,
        openChangeTag,
        showPremiumDialog,
        use12HoursFormat,
        isColorPickerVisible,
        displayColorPicker,
        displayRecurrence,
        rruleDaysMonthsTranslations,
        rruleTranslations,
        isOpen,
        addParticipant,
        ressourceValue,
        tagsOnChange
    };
};
