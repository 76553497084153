import { jsx as _jsx } from "react/jsx-runtime";
import { ExclamationCircleIcon, HorizontalRuleIcon, LinkIcon, QnaIcon } from "@fluentui/react-northstar";
import { useTranslate } from "../../hook/useTranslate";
import { translations } from "../../translations";
import { generateGUID } from "../../utils/generateGUID";
import { BASE_TEAMS_PRO_URL, SUGGEST_FEATURE_URL } from "../../const/const";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { LightbulbFilament24Regular } from "@fluentui/react-icons";
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal, setModalWithData } from "../../redux/reducers/ModalReducer/ModalReducer";
import { Modal } from "../../interfaces/Modal";
import { useNewsCache } from "../../hook/useNewsCache";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { convertFromToAppType, getHelpCenterUrl } from "./InformationMenu.utils";
export const useInformationMenu = (props) => {
    const t = useTranslate(translations);
    const dispatchCtx = useDispatch();
    const { locale } = useMsTeamsSelector("locale");
    const { from } = useAppConfigurationSelector("from");
    const { queryNews } = useNewsCache({ appType: convertFromToAppType(from) });
    const openAboutDialog = useCallback(() => dispatchCtx(openModal(Modal.ABOUT)), []);
    const openWhatsNews = useCallback(() => dispatchCtx(setModalWithData({ isOpen: Modal.NEWS, data: { skipLocalStorage: true } })), []);
    const linkRedirection = (url) => window.open(url, "_blank");
    const defaultMenu = [
        { key: generateGUID(), icon: _jsx(ExclamationCircleIcon, { outline: true }), content: t("About"), onClick: openAboutDialog },
        {
            key: generateGUID(),
            icon: _jsx(QnaIcon, { outline: true }),
            content: t("HelpCenter"),
            onClick: () => linkRedirection(getHelpCenterUrl(from)),
        },
        {
            key: generateGUID(),
            icon: _jsx(LightbulbFilament24Regular, {}),
            content: t("SuggestFeature"),
            onClick: () => linkRedirection(SUGGEST_FEATURE_URL),
        },
        {
            key: generateGUID(),
            icon: _jsx(LinkIcon, { outline: true }),
            content: "Teams Pro",
            onClick: () => linkRedirection(`${BASE_TEAMS_PRO_URL}${locale === "fr" ? "fr" : "en"}`),
        },
        {
            key: generateGUID(),
            icon: _jsx(HorizontalRuleIcon, { outline: true }),
            content: t("WhatsNewsOn"),
            onClick: openWhatsNews,
            disabled: !queryNews.data?.length,
        },
    ];
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        if (queryNews.status !== "success" && queryNews.status !== "error")
            return;
        if (props.customItems) {
            initCustomItems();
        }
        else {
            setMenu([...defaultMenu]);
        }
    }, [props.customItems, from, queryNews.status]);
    const initCustomItems = () => setMenu([...defaultMenu, ...props.customItems]);
    return { t, menu };
};
