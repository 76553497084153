import { createSlice } from "@reduxjs/toolkit";
import { generateGUID } from "../../../utils/generateGUID";
import { getSliceSelector } from "../../utils";
const initialState = {
    messages: [],
};
const errorSlice = createSlice({
    name: "error",
    initialState: initialState,
    reducers: {
        setErrorMessage(state, action) {
            state.messages = [...state.messages, { message: action.payload, id: generateGUID() }];
        },
        removeErrorMessage(state, action) {
            state.messages = state.messages.filter((m) => m.id !== action.payload);
        },
        removeAllMessage(state) {
            state.messages = [];
        },
    },
});
export const { setErrorMessage, removeErrorMessage, removeAllMessage } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
export const useErrorSelector = getSliceSelector(errorSlice);
