import { IAppFrom } from "../../interfaces/IAppFrom";
import { IAppType } from "../../interfaces/IAppType";
export const convertFromToAppType = (from) => {
    const map = {
        [IAppFrom.MAP]: IAppType.MAP,
        [IAppFrom.CALENDAR]: IAppType.CALENDAR,
        [IAppFrom.BOOKING]: IAppType.CALENDAR,
        [IAppFrom.PARKING]: IAppType.CALENDAR,
        [IAppFrom.CONTACT]: IAppType.CONTACT,
        [IAppFrom.GANTT]: IAppType.GANTT,
        [IAppFrom.KEEPASS]: IAppType.KEEPASS,
    };
    return map[from];
};
const convertFromToHelpCenterUrl = (from) => {
    const map = {
        [IAppFrom.MAP]: "map",
        [IAppFrom.CALENDAR]: "calendar",
        [IAppFrom.BOOKING]: "booking-room",
        [IAppFrom.PARKING]: "parking",
        [IAppFrom.CONTACT]: "contacts",
        [IAppFrom.GANTT]: "gantt",
        [IAppFrom.KEEPASS]: "keepass",
    };
    return map[from];
};
export const getHelpCenterUrl = (from) => {
    const appName = convertFromToHelpCenterUrl(from);
    return `https://docs.teams-pro.com/solutions/${appName}-pro/overview.html`;
};
