import { IOptionDayCustomRecurrence, IOptionDayStartCustomRecurrence, IOptionFrequencyRecurrence } from "../../../interfaces/Enums";
import { useEffect, useState } from "react";
import { calculateDatesRangeDuration, formatDateWithoutTimezone, generateDropdownItems, getDaysOfMonthFromDate } from "../../event/eventUtils";
import moment from "moment-timezone";
const displayOptionFrequencyRecurrence = generateDropdownItems(IOptionFrequencyRecurrence, ["Day", "Week", "Month", "Year"]);
const displayDayStartCustomRecurrence = generateDropdownItems(IOptionDayStartCustomRecurrence, ["First", "Second", "Third", "Fourth", "Last"]);
;
const displayDayCustomRecurrence = generateDropdownItems(IOptionDayCustomRecurrence, ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
export const frequencyString = [
    { content: IOptionFrequencyRecurrence.Day, value: "daily" },
    { content: IOptionFrequencyRecurrence.Week, value: "weekly" },
    { content: IOptionFrequencyRecurrence.Month, value: "monthly" },
    { content: IOptionFrequencyRecurrence.Year, value: "yearly" }
];
export const frequencyMaxDurations = [
    { header: IOptionFrequencyRecurrence.Day, key: 86400000 },
    { header: IOptionFrequencyRecurrence.Week, key: 604800000 },
    { header: IOptionFrequencyRecurrence.Month, key: 2678000000 },
    { header: IOptionFrequencyRecurrence.Year, key: 2678000000 * 12 }
];
export const useLogic = (props) => {
    const [resetSelectedDate, setResetSelectedDate] = useState(false);
    const [state, setState] = useState({
        monthOnTheOption: false,
        optionDayStartCustomReccurence: IOptionDayStartCustomRecurrence.First,
        optionDayCustomReccurence: IOptionDayCustomRecurrence.Monday,
        isRecurrenceCustomValid: true,
        isCount: false,
        count: 0,
        daysofmonth: [],
        weeks: [],
        endNever: props.event?.rrule?.until === null,
        endWithDate: props.event?.rrule?.until !== null,
        weeklyInterval: 1,
        daysRecuSelected: [],
        dateEndRecurrence: formatDateWithoutTimezone(props.event?.rrule?.until === null ?
            props.event?.startDate : props.event?.rrule?.until),
        rrule: {},
        optionFrequencyReccurence: frequencyString.find(f => f.value === props.event?.rrule?.freq)?.content || IOptionFrequencyRecurrence.Day
    });
    useEffect(() => {
        const weekDayNames = moment.weekdays().map(item => item);
        let firstDay = moment.weekdays()[1];
        let idx = weekDayNames.findIndex((w) => w === firstDay);
        let items = weekDayNames.map((item, i) => i < idx ? item : "");
        let daysToPush = items.filter((v) => v);
        weekDayNames.push(...daysToPush);
        daysToPush.forEach(() => weekDayNames.splice(0, 1));
        let daysofmonth = getDaysOfMonthFromDate(state.dayStartRecurrence);
        let eventDuration = props.event?.startDate && props.event?.endDate ? calculateDatesRangeDuration(props.event.startDate, props.event.endDate) : 1;
        let isRecurrenceCustomValid = isRecurrenceValid(state.optionFrequencyReccurence, eventDuration);
        const dayStartRecurrence = calculateRRuleStartDate(props.event ?? {});
        const dateEndRecurrence = formatDateWithoutTimezone(moment(dayStartRecurrence).add(1, "days"));
        setState((prev) => ({ ...prev,
            daysofmonth: daysofmonth,
            weeks: weekDayNames,
            dayStartRecurrence: dayStartRecurrence,
            daysRecuSelected: props.event?.rrule?.byweekday ?? props.event?.rrule?.bymonthday ?? [],
            weeklyInterval: props.event?.rrule?.interval ?? 1,
            optionDayStartCustomReccurence: getRecurrenceStartDay(props.event?.rrule?.bysetpos),
            optionDayCustomReccurence: props.event?.rrule?.byweekday ?
                props.event?.rrule?.byweekday[0] : IOptionDayCustomRecurrence.Monday,
            rrule: { ...props.event?.rrule },
            isCount: !!props.event?.rrule?.count,
            endNever: isRRuleNeverEnding(props.event?.rrule?.until, props.event?.rrule?.count),
            endWithDate: !!props.event?.rrule?.until,
            count: props.event?.rrule?.count || 1,
            isRecurrenceCustomValid: isRecurrenceCustomValid,
            monthOnTheOption: !!props.event?.rrule?.bysetpos,
            dateEndRecurrence: dateEndRecurrence }));
    }, []);
    /**
    * Get recurrence start day
    * @param bysetpos
    */
    const getRecurrenceStartDay = (bysetpos) => {
        if (!bysetpos || !bysetpos[0])
            return IOptionDayStartCustomRecurrence.First;
        if (bysetpos[0] > 0)
            return (bysetpos[0] - 1);
        if (bysetpos[0] < 0)
            return IOptionDayStartCustomRecurrence.Last;
    };
    /**
     * Calculate rrule start date
     * @param event
     */
    const calculateRRuleStartDate = (event) => {
        const start = !event?.rrule ? event?.startDate : event?.rrule?.dtstart;
        return formatDateWithoutTimezone(start);
    };
    /**
     * Is rrule never ending ?
     * @param until
     * @param count
     */
    const isRRuleNeverEnding = (until, count) => {
        return !until && !count;
    };
    /**
     * Is recurrence valid ?
     * @param indexDropdown
     * @param duration
     */
    const isRecurrenceValid = (indexDropdown, duration) => {
        if (state.optionFrequencyReccurence === undefined)
            return true;
        let res = frequencyMaxDurations.find(f => f.header === indexDropdown)?.key;
        return duration <= res;
    };
    /**
     * On recurrence end with a custom date
     * @param event checkbox event
     * @param data checkbox date
     */
    const onToggleRecurrenceEndWithDate = (event, data) => {
        const toggle = data?.checked ?? false;
        if (!toggle) {
            onToggleRecurrenceNeverEnd(null, { checked: true });
            return;
        }
        const until = formatDateWithoutTimezone(moment(state.dayStartRecurrence).add(1, "days"));
        const rrule = { ...state.rrule, until, count: undefined };
        setState((prev) => ({ ...prev,
            rrule,
            dateEndRecurrence: rrule.until,
            endWithDate: true,
            endNever: false,
            isCount: false
        }));
    };
    /**
     * On toggle recurrence never end
     * @param event checkbox event
     * @param data checkbox data
     */
    const onToggleRecurrenceNeverEnd = (event, data) => {
        const toggle = data?.checked ?? false;
        if (!toggle) {
            onToggleRecurrenceEndWithDate(event, { checked: true });
            return;
        }
        setState((prev) => ({ ...prev,
            endNever: true,
            endWithDate: false,
            isCount: false,
            rrule: { ...state.rrule, until: null, count: undefined }
        }));
    };
    /**
     * On repeat each specific day of month (ex: the first monday of month)
     */
    const onRepeatSpecificDayNumberOfMonth = () => {
        let stateUpdate = {};
        if (!state.monthOnTheOption) {
            stateUpdate.rrule = { ...state.rrule, byweekday: [0], bysetpos: [1], bymonthday: undefined };
            setResetSelectedDate(true);
        }
        else {
            let dayOfMonth = moment(state.rrule?.dtstart).date();
            stateUpdate.rrule = {
                ...state.rrule,
                byweekday: undefined,
                bysetpos: null,
                byyearday: undefined,
                bymonthday: [dayOfMonth]
            };
            stateUpdate.daysRecuSelected = [];
            setResetSelectedDate(false);
        }
        //stateUpdate.isRecurrenceCustomValid = true;
        stateUpdate.monthOnTheOption = !state.monthOnTheOption;
        setState((prev) => ({ ...prev,
            monthOnTheOption: stateUpdate.monthOnTheOption,
            rrule: stateUpdate.rrule,
            daysRecuSelected: stateUpdate.daysRecuSelected,
        }));
    };
    /**
     * On recurrence end with a specific date
     * @param event datepicker event
     * @param data datepicker data
     */
    const onRecurrenceEndWithDate = (event, data) => {
        let date = data?.value;
        if (!date)
            return;
        date = formatDateWithoutTimezone(moment(date));
        setState((prev) => ({ ...prev,
            dateEndRecurrence: date,
            rrule: { ...state.rrule, until: date }
        }));
    };
    /**
     * On change recurrence weekly interval
     * @param e
     */
    const onChangeWeeklyInterval = (e) => {
        const interval = +e.target.value;
        if (interval < 1 || interval > 365)
            return;
        setState((prev) => ({ ...prev, weeklyInterval: interval, rrule: { ...state.rrule, interval: interval } }));
    };
    /**
     * On change recurrence occurences
     * @param event input event
     * @param data input date
     */
    const onChangeRecurrenceOccurences = (event, data) => {
        const count = parseInt(data?.value ?? "0");
        if (count > 0 && state.isCount) {
            setState((prev) => ({ ...prev, count: +count, rrule: { ...state.rrule, count: +count } }));
        }
    };
    /**
     * On recurrence ends with a specific number of occurences
     * @param event checkbox event
     * @param data checkbox data
     */
    const onToggleRecurrenceEndWithOccurences = (event, data) => {
        const toggle = data?.checked ?? false;
        if (!toggle) {
            onToggleRecurrenceNeverEnd(event, { checked: true });
            return;
        }
        setState((prev) => ({ ...prev,
            endNever: false,
            endWithDate: false,
            isCount: true,
            rrule: { ...state.rrule, count: state.count, until: undefined },
        }));
    };
    /**
     * On change recurrence start date
     * @param event datepicker event
     * @param data datepicker data
     */
    const onChangeRecurrenceStartDate = (event, data) => {
        const startDate = data?.value?.toISOString();
        if (!startDate)
            return;
        let oldRuleDateStart = moment(state.dayStartRecurrence);
        let newStartDate = moment(startDate).set({
            h: oldRuleDateStart.hours(), m: oldRuleDateStart.minutes(), s: oldRuleDateStart.seconds()
        });
        let daysofmonth = getDaysOfMonthFromDate(newStartDate);
        setState((prev) => ({ ...prev,
            daysofmonth: daysofmonth,
            rrule: {
                ...state.rrule,
                dtstart: formatDateWithoutTimezone(startDate)
            },
            dayStartRecurrence: newStartDate.toISOString()
        }));
    };
    /**
     * On repeat recurrence on specific day of week
     * @param isRRuleUpdated
     * @param day
     * @param toAdd
     */
    const onRepeatWeeklyDay = (isRRuleUpdated, day, toAdd) => {
        if (!isRRuleUpdated)
            return;
        let idx = 0;
        let newDays = new Array();
        let days = state.daysRecuSelected ?? new Array();
        let rrule = { ...state.rrule };
        if (toAdd) {
            if (!days.includes(day))
                days.push(day);
            switch (state.optionFrequencyReccurence) {
                case 1:
                    // frequency by week
                    rrule = { ...rrule, byweekday: days, bymonthday: undefined, byyearday: undefined };
                    break;
                case 2:
                    // frequency by month
                    rrule = { ...rrule, bymonthday: days, byweekday: undefined, byyearday: undefined };
                    break;
                default:
                    break;
            }
            setState((prev) => ({ ...prev, daysRecuSelected: days, rrule }));
        }
        else {
            switch (state.optionFrequencyReccurence) {
                case 1:
                    // Weekly
                    idx = days.findIndex((w) => w === day);
                    newDays = days.filter((v, index) => index !== idx);
                    rrule = { ...rrule, byweekday: newDays, bymonthday: undefined, byyearday: undefined };
                    break;
                case 2:
                    // Monthly
                    idx = days.findIndex((w) => w === day);
                    newDays = days.filter((v, index) => index !== idx);
                    rrule = { ...rrule, byweekday: undefined, byyearday: undefined, bymonthday: newDays };
                    break;
                default:
                    break;
            }
            setState((prev) => ({ ...prev, daysRecuSelected: newDays, rrule }));
        }
    };
    /**
     * Format recurrence frequency
     */
    const formatRecurrenceFrequency = () => {
        if (!!displayOptionFrequencyRecurrence)
            return displayOptionFrequencyRecurrence.find(f => f.value === state.optionFrequencyReccurence)?.header;
        return;
    };
    /**
     * Render recurrence frequency options
     */
    const renderRecurrenceFrequencyOptions = () => {
        return displayOptionFrequencyRecurrence.map((item) => item.header);
    };
    /**
     * Format recurrence start day
     */
    const formatRecurrenceStartDay = () => {
        if (!!displayDayStartCustomRecurrence)
            return displayDayStartCustomRecurrence.find(f => f.value === state.optionDayStartCustomReccurence)?.header;
        return;
    };
    /**
     * Render recurrence start day options
     */
    const renderRecurrenceStartDayOptions = () => {
        return displayDayStartCustomRecurrence.map((item) => item.header);
    };
    /**
     * Format recurrence day
     */
    const formatRecurrenceDay = () => {
        if (!!displayDayCustomRecurrence)
            return displayDayCustomRecurrence.find(f => f.value === state.optionDayCustomReccurence)?.header;
        return;
    };
    /**
     * Render recurrence day options
     */
    const renderRecurrenceDayOptions = () => {
        return displayDayCustomRecurrence.map((item) => item.header);
    };
    /**
     * On change recurrence day option
     * @param event
     * @param data
     */
    const onChangeRecurrenceDayOption = (event, data) => {
        let dayOfMonthCustom = displayDayCustomRecurrence.find(f => f.value === data.highlightedIndex)?.value;
        setState((prev) => ({ ...prev,
            optionDayCustomReccurence: dayOfMonthCustom,
            rrule: { ...state.rrule, freq: "monthly", byweekday: [data.highlightedIndex], bymonthday: undefined }
        }));
    };
    /**
     * On change recurrence start day option
     * @param event
     * @param data
     */
    const onChangeRecurrenceStartDayOption = (event, data) => {
        let result = displayDayStartCustomRecurrence.find(f => f.value === data.highlightedIndex)?.value;
        setState((prev) => ({ ...prev,
            rrule: {
                ...state.rrule, bysetpos: [
                    data.highlightedIndex >= 0 && data.highlightedIndex < 4 ? data.highlightedIndex + 1 : -1
                ]
            },
            optionDayStartCustomReccurence: result
        }));
    };
    /**
     * On change recurrence frequency option
     * @param event
     * @param data
     */
    const onChangeRecurrenceFrequencyOption = (event, data) => {
        if (!props.event)
            return;
        let eventDuration = calculateDatesRangeDuration(props.event.startDate, props.event.endDate);
        let isRecurrenceCustomValid = isRecurrenceValid(data.highlightedIndex, eventDuration);
        if (!displayOptionFrequencyRecurrence) {
            setState((prev) => ({ ...prev, isRecurrenceCustomValid }));
            return;
        }
        const frequency = displayOptionFrequencyRecurrence.find(f => f.value === data.highlightedIndex)?.value;
        const rruleFrequency = frequencyString.find(f => f.content === frequency)?.value;
        let stateUpdate = {};
        stateUpdate.isRecurrenceCustomValid = isRecurrenceCustomValid;
        stateUpdate.optionFrequencyReccurence = frequency;
        if (data.highlightedIndex >= 0 && data.highlightedIndex <= 3)
            stateUpdate.daysRecuSelected = new Array();
        const rrule = { ...state.rrule };
        rrule.freq = rruleFrequency;
        switch (data.highlightedIndex) {
            case IOptionFrequencyRecurrence.Day:
                rrule.byyearday = undefined;
                rrule.bymonthday = undefined;
                rrule.byweekday = undefined;
                rrule.bysetpos = null;
                break;
            case IOptionFrequencyRecurrence.Week:
                rrule.byyearday = undefined;
                rrule.bymonthday = undefined;
                rrule.bysetpos = null;
                break;
            case IOptionFrequencyRecurrence.Month:
                let daysofmonth = getDaysOfMonthFromDate(state.dayStartRecurrence);
                stateUpdate.daysofmonth = daysofmonth;
                rrule.byyearday = undefined;
                rrule.byweekday = undefined;
                break;
            case IOptionFrequencyRecurrence.Year:
                rrule.byweekday = undefined;
                rrule.bymonthday = undefined;
                rrule.bysetpos = null;
                break;
            default:
                break;
        }
        stateUpdate.rrule = rrule;
        setState((prev) => ({ ...prev,
            rrule: stateUpdate.rrule,
            isRecurrenceCustomValid: stateUpdate.isRecurrenceCustomValid,
            optionFrequencyReccurence: stateUpdate.optionFrequencyReccurence,
            daysRecuSelected: stateUpdate.daysRecuSelected,
            daysofmonth: stateUpdate.daysofmonth ?? [],
        }));
    };
    /**
     * On save Rrule
     */
    const onSaveRrule = () => {
        if (state.isRecurrenceCustomValid)
            props.onConfirm({ ...state.rrule });
    };
    /**
     * Format date to locale date string
     * @param date
     */
    const formatDateToLocaleString = (date) => date.toLocaleDateString();
    return {
        state,
        resetSelectedDate,
        onRepeatSpecificDayNumberOfMonth,
        onRecurrenceEndWithDate,
        onChangeWeeklyInterval,
        onChangeRecurrenceOccurences,
        onToggleRecurrenceEndWithOccurences,
        onChangeRecurrenceStartDate,
        onRepeatWeeklyDay,
        formatRecurrenceFrequency,
        renderRecurrenceFrequencyOptions,
        formatRecurrenceStartDay,
        renderRecurrenceStartDayOptions,
        formatRecurrenceDay,
        renderRecurrenceDayOptions,
        onChangeRecurrenceDayOption,
        onChangeRecurrenceStartDayOption,
        onChangeRecurrenceFrequencyOption,
        onSaveRrule,
        formatDateToLocaleString,
        onToggleRecurrenceNeverEnd,
        onToggleRecurrenceEndWithDate
    };
};
