export var IAppFrom;
(function (IAppFrom) {
    IAppFrom["MAP"] = "Map";
    IAppFrom["CALENDAR"] = "Calendar";
    IAppFrom["BOOKING"] = "Booking";
    IAppFrom["PARKING"] = "Parking";
    IAppFrom["CONTACT"] = "Contact";
    IAppFrom["KEEPASS"] = "Keepass";
    IAppFrom["GANTT"] = "Gantt";
})(IAppFrom || (IAppFrom = {}));
