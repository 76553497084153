import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalWithData, useModalSelector } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "../../../interfaces/Modal";
import { translations, useMsTeamsSelector, useTranslate } from "../../../..";
import { useLocation } from "react-router-dom";
import { useNewsCache } from "../../../hook/useNewsCache";
import { isFrequencyIsReached, onBuildNews } from "./NewsDialog.utils";
export const useNewsLogic = (props) => {
    // General
    const location = useLocation();
    const dispatchCtx = useDispatch();
    const t = useTranslate(translations);
    // Context
    const { isOpen, data } = useModalSelector("isOpen", "data");
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const infosApp = useSelector((state) => state.infosApp);
    // Caches
    const { queryNews } = useNewsCache({ appType: props.AppType });
    // States
    const [allNews, setAllNews] = useState([]);
    const [titleIndex, setTitleIndex] = useState(0);
    const isNotInsideRestrictedRoutes = !location.pathname.includes("/remove") && !location.pathname.includes("/configuration") && !location.pathname.includes("/widget");
    useEffect(() => {
        if (!props.Preview)
            return;
        let news = [];
        onBuildNews(props.Preview.news, props.Preview.id, news);
        setAllNews(news);
    }, [props.Preview]);
    useEffect(() => {
        if (queryNews.isLoading || props.Preview)
            return;
        getNewCampaigns();
    }, [queryNews.isLoading, props.Preview, data]);
    const getNewCampaigns = () => {
        if (!queryNews.data?.length)
            return;
        const news = filterNewsToDisplay();
        if (!news.length)
            return;
        if (canSkipLocalStorage()) {
            displayNews(news);
        }
        else {
            dispatchCtx(openModal(Modal.NEWS));
            displayNews(news);
        }
        infosApp?.appInsightInstance?.trackEvent({
            name: "News_Dialog_Open",
        });
    };
    const filterNewsToDisplay = () => {
        if (canSkipLocalStorage())
            return queryNews.data.map((item) => item.id);
        const { localStorage } = window;
        const newCampaigns = queryNews.data.map((n) => {
            if (localStorage.getItem(`@WITIVIO-${props.AppType}-${n.id}`))
                return filterSequences(n);
            return n.id;
        });
        return newCampaigns.filter((nc) => nc);
    };
    const displayNews = (newCampaigns) => {
        let news = [];
        queryNews.data.forEach((n) => {
            if (!canSkipLocalStorage()) {
                if (!newCampaigns.includes(n.id))
                    return;
                const stringifyResponse = JSON.stringify({ id: n.id, lastShow: Date.now() });
                window.localStorage.setItem(`@WITIVIO-${props.AppType}-${n.id}`, stringifyResponse);
            }
            onBuildNews(n.news, n.id, news);
        });
        setAllNews(news);
    };
    const canSkipLocalStorage = () => !!data?.skipLocalStorage;
    const getCurrentNewsInfo = useCallback(() => {
        if (!queryNews.data || !allNews.length)
            return;
        const getCurrentId = allNews[titleIndex].id;
        const campaign = queryNews.data.find((qn) => qn.id === getCurrentId);
        return campaign;
    }, [queryNews.data, allNews, titleIndex, props.Preview]);
    const filterSequences = (campaign) => {
        const res = filterByFrequency(campaign);
        if (!res)
            return;
        return campaign.id;
    };
    const filterByFrequency = (campaign) => {
        const item = window.localStorage.getItem(`@WITIVIO-${props.AppType}-${campaign.id}`);
        if (!item)
            return;
        const { lastShow } = JSON.parse(item);
        return isFrequencyIsReached(campaign.frequency, lastShow) ? campaign.id : undefined;
    };
    const closeDialog = () => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
    const openCTALink = () => {
        const url = getCurrentNewsInfo()?.ctaUrl;
        infosApp?.appInsightInstance?.trackEvent({
            name: "News_CTA_Clicked_" + getCurrentNewsInfo().ctaLabel,
        });
        window.open(url, "_blank");
        dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
    };
    return {
        closeDialog,
        allNews,
        isLoading: queryNews.isLoading && !props.Preview,
        getCurrentNewsInfo,
        setTitleIndex,
        isOnMobile,
        isNotInsideRestrictedRoutes,
        t,
        isOpen,
        openCTALink,
    };
};
