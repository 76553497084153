import SideViewTop from "../SideViewTop/SideViewTop";
import SideViewBottom from "../SideViewBottom/SideViewBottom";
import "../SideView.scss";
import { Checkbox, Flex, Form, FormInput, FormTextArea, Text } from "@fluentui/react-northstar";
import { useSideViewPlaces } from "./SideViewPlaces.logic";
import React from "react";
import SideViewItem from "../../../components/SideView/SideViewItem/SideViewItem";
import { DESCRIPTION_MAX_LENGTH, TITLE_MAX_LENGTH } from "../../../const/const";
import { IView } from "../../../types/IView/IView";
import { GoogleRenderComponent } from "front";

const SideViewPlaces = (props: { isOpen: boolean; handleClose: () => void }) => {
  const logic = useSideViewPlaces(props);

  const displayAllPlaces = () => (
    <Flex column style={{ width: "100%" }}>
      <div style={{ marginBottom: "1rem" }}>
        <Checkbox label={logic.t("DisplayAllPlaces")} toggle defaultChecked={true} onClick={logic.toggleAllMarkers} />
      </div>
      {logic.places.map((item) => (
        <SideViewItem
          key={item.id}
          id={item.id as string}
          title={item.title as string}
          handleEdit={logic.handleEditMode}
          handleDelete={logic.handleOpenDeletePlaceDialog}
          handleShow={logic.handleShow}
          show={Boolean(logic.markers.get(item.id as string)?.getVisible())}
          isDisabled={item.isDisabled}
        />
      ))}
    </Flex>
  );

  const displayForm = () => (
    <>
      <Form style={{ width: "100%" }}>
        <FormInput
          fluid
          placeholder={logic.t("AddTitle")}
          label={logic.t("TitleModalPlaceTitle")}
          icon={<Text styles={{ color: "grey" }} content={logic.title ? logic.title.length + "/" + TITLE_MAX_LENGTH : `0/${TITLE_MAX_LENGTH}`} />}
          id="title"
          errorMessage={logic.errors.title && logic.errors.title.type === "required" && logic.t("MessageRequired")}
          max={TITLE_MAX_LENGTH}
          maxLength={TITLE_MAX_LENGTH}
          {...logic.register("title", { required: true, maxLength: TITLE_MAX_LENGTH })}
          value={logic.title}
          className="sideView-form-input-label"
        />

        <Flex fill vAlign="start" hAlign="start" column className="sideView-google-input-container">
          <Text content={logic.t("TitleModalPlaceAddress")} style={{ marginBottom: ".2rem" }} className="sideView-text-input-label" />
          <GoogleRenderComponent address={logic.address} disabled={false} readOnly={false} updateAddress={logic.handleAddress} hideLabel fill inverted={false} />
        </Flex>

        <FormTextArea
          fluid
          id="description"
          label={
            <Flex space="between">
              <Text content={logic.t("TitleModalPlaceDescription")} />
              <Text styles={{ color: "grey" }} content={logic.description ? logic.description.length + "/" + DESCRIPTION_MAX_LENGTH : `0/${DESCRIPTION_MAX_LENGTH}`} />
            </Flex>
          }
          maxLength={DESCRIPTION_MAX_LENGTH}
          style={{ minHeight: 100 }}
          {...logic.register("description")}
          value={logic.description}
        />
      </Form>
    </>
  );

  return (
    <Flex
      className={props.isOpen ? `sideView-container sideView-container-open sideView-premium-${logic.isPremium}` : `sideView-container sideView-premium-${logic.isPremium}`}
      hAlign="start"
      vAlign="start"
      space="between"
      column
      fill
    >
      <Flex hAlign="center" vAlign="center" column style={{ width: "100%" }}>
        <SideViewTop
          title={logic.mode === 0 ? logic.t("titleMenuPlaces") : logic.onEditMode ? logic.t("TitleModalEditPlace") : logic.t("TitleButtonAddPlace")}
          onClose={logic.onClose}
          showCloseBtn={logic.mode === 0}
        />
        <Flex fill vAlign="start" hAlign="start" style={{ padding: "1rem 0" }}>
          {logic.mode === 0 ? <>{displayAllPlaces()}</> : <>{displayForm()}</>}
        </Flex>
      </Flex>
      <SideViewBottom
        view={IView.PLACES}
        content={logic.t("TitleButtonAddPlace")}
        toggleMode={logic.toggleMode}
        mode={logic.mode}
        onAction={logic.handleSubmit(logic.onSubmit)}
        isDisabled={!logic.addressIsValid}
      />
    </Flex>
  );
};

export default SideViewPlaces;
