import { useEffect, useState } from "react";
import { useGraph, usePermissionsSelector } from "front";
import { useSearchParams } from "react-router-dom";
export var ActiveView;
(function (ActiveView) {
    ActiveView[ActiveView["Calendar"] = 0] = "Calendar";
    ActiveView[ActiveView["Event"] = 1] = "Event";
})(ActiveView || (ActiveView = {}));
export const useLogic = () => {
    const [view, setView] = useState({ activeView: ActiveView.Calendar });
    const [event, setEvent] = useState();
    const { userPerm } = usePermissionsSelector("userPerm");
    let [searchParams] = useSearchParams();
    useGraph({ forceAsk: undefined });
    useEffect(() => {
        const draft = searchParams.get("draft");
        if (!draft || !userPerm?.perm)
            return;
        showView(ActiveView.Event, JSON.parse(decodeURIComponent(draft)));
    }, [searchParams, userPerm]);
    /**
     * Show view
     * @param activeView activeView to show
     */
    const showView = async (activeView, event = undefined, isAlreadyUpdated = false, events = [], queryKey = []) => {
        if (activeView == ActiveView.Calendar) {
            setView({ activeView });
            setEvent({ event, isAlreadyUpdated, events, queryKey });
            return;
        }
        setEvent({ event, isAlreadyUpdated, events, queryKey });
        setView({ activeView });
    };
    return {
        view,
        showView,
        event
    };
};
