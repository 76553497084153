export var Modal;
(function (Modal) {
    Modal["PREMIUM"] = "premium";
    Modal["PERMISSIONS_ADDMEMBER"] = "permissions_addMember";
    Modal["PERMISSIONS_CANCEL_UNSAVE_CHANGE"] = "permissions_cancel_unsave_change";
    Modal["PERMISSIONS_DELETE_CALENDAR_SYNC"] = "permissions_delete_calednar_sync";
    Modal["PERMISSIONS_ROLE_CHANGE"] = "permissions_role_change";
    Modal["NOTIFICATIONS"] = "notifications";
    Modal["CANCEL_APPOINTMENT"] = "cancel_appointment";
    Modal["NEWS"] = "news";
    Modal["CONFIRM"] = "confirm";
    Modal["NEED_CONSENT"] = "need_consent";
    Modal["SHOW_ERROR"] = "show_error";
    Modal["CONFIRM_CLEAR"] = "confirm_clear";
    Modal["CONFIRM_CLOSE"] = "confirm_close";
    Modal["UPGRADE"] = "upgrade";
    Modal["ABOUT"] = "about";
})(Modal || (Modal = {}));
