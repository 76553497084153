import SideViewTop from "../SideViewTop/SideViewTop";
import SideViewBottom from "../SideViewBottom/SideViewBottom";
import "../SideView.scss";
import { Button, Flex, Form, FormInput, FormTextArea, Text } from "@fluentui/react-northstar";
import React from "react";
import SideViewItem from "../../../components/SideView/SideViewItem/SideViewItem";
import { useSideViewTrips } from "./SideViewTrips.logic";
import { Controller } from "react-hook-form";
import { typeInputs } from "./SideViewTrips.data";
import { DESCRIPTION_MAX_LENGTH, TITLE_MAX_LENGTH } from "../../../const/const";
import { IView } from "../../../types/IView/IView";
import { GoogleRenderComponent, IGoogleInput } from "front";

const SideViewTrips = (props: { isOpen: boolean; handleClose: () => void }) => {
  const logic = useSideViewTrips(props);

  const displayAllPlaces = () => (
    <Flex column style={{ width: "100%" }}>
      {logic.trips.map((item) => (
        <SideViewItem
          key={item.id}
          id={item.id as string}
          title={item.title as string}
          handleEdit={logic.handleEditMode}
          handleDelete={logic.handleOpenDeleteTripDialog}
          handleShow={logic.handleShow}
          show={logic.visibleTrip === item.id}
          isDisabled={item.isDisabled}
        />
      ))}
    </Flex>
  );

  const displayForm = () => (
    <>
      <Form style={{ width: "100%" }}>
        <Flex hAlign="center" vAlign="center" space="between">
          <Text content="Type" className="sideView-text-input-label" />
          <Flex gap="gap.small">
            <Controller
              name={"type"}
              control={logic.control}
              render={({ field: { onChange, value } }) => (
                <Flex gap="gap.small">
                  {typeInputs.map((inp) => (
                    <Button
                      data-selected={value === inp.key}
                      key={inp.key}
                      className="sideView-type-button"
                      data-theme={logic.themeClass}
                      icon={inp.icon}
                      circular
                      onClick={() => onChange(inp.key)}
                      primary={value === inp.key}
                      styles={{
                        borderTopRightRadius: "62.4375rem !important",
                        borderTopLeftRadius: "62.4375rem !important",
                        borderBottomRightRadius: "62.4375rem !important",
                        borderBottomLeftRadius: "62.4375rem !important",
                      }}
                    />
                  ))}
                </Flex>
              )}
            />
          </Flex>
        </Flex>

        <FormInput
          fluid
          placeholder={logic.t("AddTitle")}
          label={logic.t("TitleModalPlaceTitle")}
          icon={<Text styles={{ color: "grey" }} content={logic.title.length + "/" + TITLE_MAX_LENGTH} />}
          id="title"
          showSuccessIndicator={!logic.errors.title && !!logic.watch("title")}
          errorMessage={logic.errors.title && logic.errors.title.type === "required" && logic.t("MessageRequired")}
          max={TITLE_MAX_LENGTH}
          maxLength={TITLE_MAX_LENGTH}
          {...logic.register("title", { required: true, maxLength: TITLE_MAX_LENGTH })}
          value={logic.watch("title")}
          className="sideView-form-input-label"
        />

        <Flex fill vAlign="start" hAlign="start" column className="sideView-google-input-container">
          <Text content={logic.t("TitleModalTripFrom")} style={{ marginBottom: ".2rem" }} className="sideView-text-input-label" />
          <GoogleRenderComponent
            address={logic.googleInputs.from.input.values}
            disabled={false}
            readOnly={false}
            updateAddress={(data: IGoogleInput) => logic.handleAddress(data, "from")}
            hideLabel
            fill
            inverted={false}
          />
        </Flex>

        <Flex fill vAlign="start" hAlign="start" column className="sideView-google-input-container">
          <Text content={logic.t("TitleModalTripTo")} style={{ marginBottom: ".2rem" }} className="sideView-text-input-label" />
          <GoogleRenderComponent
            address={logic.googleInputs.to.input.values}
            disabled={false}
            readOnly={false}
            updateAddress={(data: IGoogleInput) => logic.handleAddress(data, "to")}
            hideLabel
            fill
            inverted={false}
          />
        </Flex>

        {!logic.googleTravel.isValid && !logic.googleTravel.isLoading && logic.googleInputs.from.input.isValid && logic.googleInputs.to.input.isValid && (
          <Text content={logic.t("NoTripFound")} error style={{ padding: "0.75rem 1rem", background: "rgba(255, 0, 0, .075)", borderRadius: 5 }} />
        )}

        <FormTextArea
          fluid
          id="description"
          label={
            <Flex space="between">
              <Text content={logic.t("TitleModalPlaceDescription")} />
              <Text styles={{ color: "grey" }} content={logic.description.length + "/" + DESCRIPTION_MAX_LENGTH} />
            </Flex>
          }
          maxLength={DESCRIPTION_MAX_LENGTH}
          style={{ minHeight: 100 }}
          {...logic.register("description")}
          value={logic.description}
        />
      </Form>
    </>
  );

  return (
    <Flex
      className={props.isOpen ? `sideView-container sideView-container-open sideView-premium-${logic.isPremium}` : `sideView-container sideView-premium-${logic.isPremium}`}
      hAlign="start"
      vAlign="start"
      space="between"
      column
      fill
    >
      <Flex hAlign="center" vAlign="center" column style={{ width: "100%" }}>
        <SideViewTop
          title={logic.mode === 0 ? logic.t("titleMenuTrips") : logic.onEditMode ? logic.t("TitleModalEditTrip") : logic.t("TitleButtonAddTrip")}
          onClose={logic.onClose}
          showCloseBtn={logic.mode === 0}
        />
        <Flex fill vAlign="start" hAlign="start" style={{ padding: "1rem 0" }}>
          {logic.mode === 0 ? <>{displayAllPlaces()}</> : <>{displayForm()}</>}
        </Flex>
      </Flex>
      <SideViewBottom
        view={IView.TRIPS}
        content={logic.t("TitleButtonAddTrip")}
        toggleMode={logic.toggleMode}
        mode={logic.mode}
        onAction={logic.handleSubmit(logic.onSubmit)}
        isDisabled={!logic.googleTravel.isValid}
      />
    </Flex>
  );
};

export default SideViewTrips;
