import axios from "axios";
import { refreshAccessToken } from "../../redux/reducers/AuthReducer/AuthReducer";
const instance = axios.create();
const config = {
    dispatch: null,
};
const setAxiosInstanceApiBaseUrl = (baseUrl) => {
    instance.defaults.baseURL = baseUrl;
};
const setAxiosConfig = (dispatch) => {
    config.dispatch = dispatch;
};
const setAxiosRequestMiddleware = (accessToken) => {
    instance.interceptors.request.clear();
    instance.interceptors.request.use(async function (config) {
        if (!config.headers)
            config.headers = {};
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json";
        }
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
};
instance.interceptors.response.use(async function (config) {
    return config;
}, function (error) {
    if (error.response.status == "401") {
        if (config.dispatch)
            config.dispatch(refreshAccessToken({ needRefresh: true, isTeamsApp: undefined }));
    }
    return Promise.reject(error);
});
export const AxiosConfig = {
    instance,
    setAxiosInstanceApiBaseUrl,
    setAxiosRequestMiddleware,
    setAxiosConfig,
};
